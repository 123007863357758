import React from "react";

import { Container } from "semantic-ui-react";

import LayoutInner from "../../components/layout/layout-inner";
import SEO from "../../components/seo/seo";
import BodyText from "../../components/body-text";
import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";

const Page = () => (
  <LayoutInner>
    <SEO title="Marketing Terms and Conditions" keywords={[`rocksteady`]} />
    <SecondaryNavBar
      product="generic"
      title="Terms Conditions"
      titlePath="/terms-conditions/"
      titleActive={true}
      links={[]}
    />
    <Container>
      <BodyText>
        <h1>
          <strong>Marketing Preferences Terms &amp; Conditions</strong>
        </h1>
        <p>
          Don’t worry we hate spam and promise to keep your email safe. We would
          just like to know what information you would like to receive from us.
        </p>

        <ul>
          <li>
            If you would like to hear about other Rocksteady products, for
            instance Summer Camps, then tick the Rocksteady opt-in box.
          </li>
          <li>
            If you would like to hear about offers from friends of Rocksteady,
            for instance, discounted music instruments, then tick the friends of
            Rocksteady box.
          </li>
          <li>
            If you would like to hear from Rocksteady and friends of Rocksteady
            then tick both boxes.
          </li>

          <li>
            We will only send you information based on the boxes you tick, so if
            you don’t want to receive either, then leave both boxes unticked.
          </li>
        </ul>
        <p>
          If you do subscribe to the latest from Rocksteady or friends of
          Rocksteady and change your mind, you can unsubscribe by clicking the
          link at the bottom of the email.
        </p>
      </BodyText>
    </Container>
  </LayoutInner>
);

export default Page;
